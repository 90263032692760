import React, { useState } from 'react';
import { NavItem } from 'reactstrap';
import { UserTemplate } from './UserAccess/UserTemplate';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { customerSignInRequest } from '../authConfig';
import { Button } from 'react-bootstrap';
import { KaEmployeeTemplate } from './UserAccess/KaEmployeeTemplate';
import { NavLink } from "react-router-dom";
import { KaEmployeeProfile, UserBusiness, UserProfile } from "./UserAccess/UserContext";
import { useUser } from "./UserAccess/useUser";
import useUserQuery from './Common/useUserQuery';
import useUserRequest from './Common/useUserRequest';
import { HttpMethod } from './Common/useFetch';
import Confirm from './Common/Confirm';
import { useKaEmployee } from './UserAccess/useKaEmployee';

export const NavButton = (props: { text: string, onClick: () => void }) => {
  return (
    <NavItem>
      <Button
        className="btn-ghost-primary"
        variant="link"
        size="sm"
        onClick={props.onClick}>
        <strong>{props.text}</strong>
      </Button>
    </NavItem>
  );
}

export function NavMenu() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const customerSignIn = () => instance.loginRedirect(customerSignInRequest);

  return (
    <header className="navbar navbar-expand-sm navbar-end navbar-light bg-white border-bottom fixed-top" style={{ zIndex: 100 }}>
      <div className="container-fluid">
        <div className="navbar-nav-wrap">
          <nav className="navbar-nav-wrap-col navbar-collapse">
            <div className="navbar-brand-wrapper" style={{ height: 'auto' }}>
              <div className="d-flex align-items-center">
                <a className="navbar-brand" href="/" aria-label="Logo">
                  <img className="m-2" src="/Constellation-text-only.png" alt="Logo" width='100' style={{ minWidth: '15rem', maxWidth: '15rem' }} />
                </a>
              </div>
            </div>
            <ul className="navbar-nav">
              {!isAuthenticated && <>
                <NavButton text="Sign In" onClick={customerSignIn} />
              </>}
              <KaEmployeeTemplate>
                <KaEmployeeMenu />
              </KaEmployeeTemplate>
              <UserTemplate>
                <UserMenu />
              </UserTemplate>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

interface IntegrationDto {
  name: string;
  configurationUri: string;
}

const integrationsSpec = (businessId: string) => ({ path: `/api/businesses/${businessId}/integrations` });

const UserMenu = () => {
  const user = useUser();
  const menuText = user ? user.email + (user.selectedBusiness ? ` : ${user.selectedBusiness.name}` : '') : '';
  const isSelectedBusiness = isBusinessForId(user.selectedBusiness?.id);
  const [businessToSelect, setBusinessToSelect] = useState<UserBusiness | undefined>(undefined);
  const { instance } = useMsal();
  const getIntegrations = useUserQuery<IntegrationDto[]>(integrationsSpec(user.selectedBusiness?.id ?? ""))
  const addTransactions = useUserRequest<void>({
    path: `/api/dev/addTicketData/${user.selectedBusiness?.id}`,
    method: HttpMethod.POST
  });
  
  const changeSelectedBusiness = (user: UserProfile, businessId: string) => {
    let business = findUserBusinessById(user, businessId);

    if (business!.sandbox && !user.selectedBusiness?.sandbox) {
      setBusinessToSelect(business!);
    } else user.setSelectedBusiness(business!);
  }

  const confirmSandboxBusiness = (business: UserBusiness) => { 
    user.setSelectedBusiness(business);
    setBusinessToSelect(undefined);
  }

  return (<>
    <Confirm
      visible={!!businessToSelect}
      title='Switch to Sandbox Mode'
      body={`Do you want to leave the production version of Constellation to enter the sandbox? 
              Data entered here will not go to production sites.`}
      onDismiss={() => setBusinessToSelect(undefined)}
      onConfirm={() => {confirmSandboxBusiness(businessToSelect!)}} />
    <li className='nav-item dropdown'>
      <button id='blogMegaMenu' className='nav-link dropdown-toggle btn btn-link'
        data-bs-toggle='dropdown' aria-expanded='false'>{menuText}</button>
      <div className='dropdown-menu'>
        <span className='dropdown-header'>Business</span>
        {user.businesses.sort((a, b) => prioritizeNonSandboxBusinesses(a, b) || alphabeticallyByName(a, b)).map(business =>
          <button
            className={'btn-ghost-secondary dropdown-item' + (isSelectedBusiness(business) ? ' bg-soft-secondary' : '')}
            key={business.id}
            onClick={() => changeSelectedBusiness(user, business.id)}>
            {<strong>{business.name}</strong>}
          </button>)}
        {user.selectedBusiness?.administrator &&
          <span>
            <div className='dropdown-divider'></div>
            <span className='dropdown-header'>Settings</span>
            <NavLink className='btn-ghost-secondary dropdown-item' to='/users'>{<strong>Users</strong>}</NavLink>
            <NavLink className='btn-ghost-secondary dropdown-item' to='/sites'>{<strong>Sites</strong>}</NavLink>
            <NavLink className='btn-ghost-secondary dropdown-item' to='/ordersettings'>{<strong>Orders</strong>}</NavLink>
            <span className='dropdown-header'>Integrations</span>
            {getIntegrations.isLoading &&
              <span className='dropdown-item'>
                <span className="spinner-border spinner-border-sm" role="status"></span> Loading integrations...
              </span>}
            {!getIntegrations.isLoading && getIntegrations.isSuccess &&
              getIntegrations.data?.map(n =>
                <a key={n.name} className='btn-ghost-secondary dropdown-item' href={n.configurationUri} target='_blank' rel='noopener'>{<strong>{n.name}</strong>}</a>)}
            {!getIntegrations.isLoading && getIntegrations.isError &&
              <span className='dropdown-item alert-danger'>Failed to load list of integrations</span>}
            <NavLink className='btn-ghost-secondary dropdown-item' to='/extIntegrations'>{<strong>External Integrations</strong>}</NavLink>
          </span>}
        <div className='dropdown-divider' />
        {
          user.isDevelopment &&
          <>
            <span className='dropdown-header'>Dev Tools</span>
            <button
              className={'btn-ghost-secondary dropdown-item btn btn-link'}
              onClick={() => addTransactions.request()}>
              {<strong>Add Transactions</strong>}
            </button>
            <div className='dropdown-divider' />
          </>
        }
        <button className='btn-ghost-secondary dropdown-item btn btn-link' onClick={() => instance.logout()}>{<strong>Sign Out</strong>}</button>
      </div>
    </li>
  </>);
};

const KaEmployeeMenu = () => {
  const user = useKaEmployee();
  const menuText = user ? user.email + (user.selectedBusiness ? ` : ${user.selectedBusiness.name}` : '') : '';
  const isSelectedBusiness = isBusinessForId(user.selectedBusiness?.id);
  const { instance } = useMsal();

  const changeSelectedBusiness = (user: KaEmployeeProfile, businessId: string) => {
    let business = findEmployeeBusinessById(user, businessId);

    user.setSelectedBusiness(business!);
  }

  return (
    <li className='nav-item dropdown'>
      <button id='blogMegaMenu' className='nav-link dropdown-toggle btn btn-link'
        data-bs-toggle='dropdown' aria-expanded='false'>{menuText}</button>
      <div className='dropdown-menu'>
        <span className='dropdown-header'>Business</span>
        {user.businesses.sort((a, b) => alphabeticallyByName(a, b)).map(business =>
          <button
            className={'btn-ghost-secondary dropdown-item' + (isSelectedBusiness(business) ? ' bg-soft-secondary' : '')}
            key={business.id}
            onClick={() => changeSelectedBusiness(user, business.id)}>
            {<strong>{business.name}</strong>}
          </button>)}
        <div className='dropdown-divider' />
        <button className='btn-ghost-secondary dropdown-item btn btn-link' onClick={() => instance.logout()}>{<strong>Sign Out</strong>}</button>
      </div>
    </li>
  );
};

const prioritizeNonSandboxBusinesses = (a: UserBusiness, b: UserBusiness) => Number(a.sandbox) - Number(b.sandbox);

const alphabeticallyByName = (a: { [name: string]: any }, b: { [name: string]: any }) => a.name.localeCompare(b.name);

const findUserBusinessById = (userProfile: UserProfile, businessId: string) =>
  userProfile.businesses.find(isBusinessForId(businessId)) ?? null;

const findEmployeeBusinessById = (employeeProfile: KaEmployeeProfile, businessId: string) =>
  employeeProfile.businesses.find(isBusinessForId(businessId)) ?? null;

const isBusinessForId = (id: string | undefined) =>
  (business: { [id: string]: any }) => business.id.toLowerCase() === id?.toLowerCase();