import React, { useState } from 'react';
import KaAlert from '../Common/KaAlert'
import { UserProfile } from '../UserAccess/UserContext';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import { HttpMethod } from '../Common/useFetch';
import { useDisplayMessage } from '../Common/useDisplayMessage';
import OrderDto, { OrderDisplayDto } from '../Common/dtos/OrderDto'
import { CreateEditOrderHeader, OrderHeader } from './CreateEditOrderHeader'
import { CreateEditOrderBlends } from './CreateEditOrderBlends'
import OrderUpsertDto, { OrderBlendUpsertDto } from '../Common/dtos/OrderUpsertDto';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { capitalizeFirstLetter, removeEmptyFields } from '../../Utilities';
import SpinnerButton from '../Common/SpinnerButton';
import BoldButton from '../Common/BoldButton';

interface IProps {
  user: UserProfile;
  mode: CreateEditMode;
  onCancelled: () => void;
  onSuccess: (orderId: string, orderNumber: string, assignSites: boolean) => void;
  setUnrecoverableDataError: (exception: Error, severityLevel?: SeverityLevel) => void;
  readOnly: boolean;
}

export type CreateEditMode =
  { create: true } |
  { create: false, initialOrder: OrderDto }

enum OrderSteps {
  Order,
  Blends
}

const ordersPath = (user: UserProfile) => `/api/businesses/${user.selectedBusiness?.id}/orders`;

const putSpec = (user: UserProfile, orderId: string): UserRequestSpec => ({
  path: `${ordersPath(user)}/${orderId}`,
  method: HttpMethod.PUT,
});

const postSpec = (user: UserProfile): UserRequestSpec => ({
  path: ordersPath(user),
  method: HttpMethod.POST,
});

const newOrder = (): OrderUpsertDto => ({ number: "", customerId: "", blends: [{ items: [] }] });

const initialOrder = (mode: CreateEditMode): OrderUpsertDto => {
  if (mode.create) {
    return newOrder();
  } else {
    const {
      id,
      hasTransactions,
      customerName,
      customerAccountNumber,
      itemNames,
      siteNames,
      createdAt,
      completedAt,
      ...upsertDto } = { ...(mode.initialOrder as OrderDisplayDto) };
    return upsertDto;
  }
}

export const CreateEditOrder = (props: IProps) => {
  const [order, setOrder] = useState<OrderUpsertDto>(initialOrder(props.mode));
  const [orderStep, setOrderStep] = useState<OrderSteps>(OrderSteps.Order);
  const [assignSites, setAssignSites] = useState(false);
  const displayMessage = useDisplayMessage();
  const formEnabled = !props.readOnly;

  const requestSpec = props.mode.create
    ? postSpec(props.user)
    : putSpec(props.user, props.mode.initialOrder.id);

  const useCreateEdit = useUserRequest<OrderUpsertDto, string>(requestSpec, {
    onSuccess: (response) => {
      const orderId = props.mode.create ? response : props.mode.initialOrder.id;
      props.onSuccess(orderId, order.number, assignSites);
    },
    onError: (e) => displayMessage.fail(e.message),
  });

  const updateOrderHeader = (updatedOrder: OrderHeader) => {
    if (formEnabled) {
      setOrder({ ...order, ...updatedOrder });
    }

    setOrderStep(OrderSteps.Blends);
  }

  const updateOrderBlends = (blends: OrderBlendUpsertDto[]) => {
    if (formEnabled) {
      setOrder({ ...order, blends: [...blends] });
    }
  }

  const cancelClicked = () => props.onCancelled();

  const saveClicked = () => {
    if (formEnabled) {
      if (canSaveOrder()) {
        setAssignSites(false);
        useCreateEdit.request(removeEmptyFields({ ...order }));
      }
    } else {
      props.onCancelled();
    }
  }

  const saveAndAssignClicked = () => {
    if (canSaveOrder()) {
      setAssignSites(true);
      useCreateEdit.request(removeEmptyFields({ ...order }));
    }
  };

  const canSaveOrder = () => {
    for (var blend of order.blends) {
      if (blend.items.length === 0) {
        displayMessage.fail("Cannot " + createOrUpdate + " order with no items");
        return false;
      }
    }

    return true;
  }

  const navigateBack = () => {
    displayMessage.clear();
    setOrderStep(OrderSteps.Order);
  }

  const createOrUpdate = props.mode.create ? "create" : "update";
  const createOrUpdateCapitalized = capitalizeFirstLetter(createOrUpdate);

  const title = props.mode.create
    ? orderStep === OrderSteps.Order ? 'Create an order' : `Order ${order.number}`
    : `${formEnabled ? 'Update' : 'View'} order ${props.mode.initialOrder.number}`;

  return (
    <div>
      <div className="w-100 d-flex">
        <h2 className="ka-blue">{title}</h2>
      </div>
      {
        !formEnabled &&
        <div className="w-100 d-flex">
          <h5>
            {!props.mode.create && props.mode.initialOrder.stage === "Complete"
              ? "Cannot edit a completed order"
              : "Cannot edit this order because it has transactions"
            }
          </h5>
        </div>
      }
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      {orderStep === OrderSteps.Order &&
        <CreateEditOrderHeader
          orderHeader={{ ...order }}
          nextClicked={updateOrderHeader}
          profile={props.user}
          cancelClicked={cancelClicked}
          enabled={formEnabled}
        />
      }
      {orderStep === OrderSteps.Blends &&
        <>
          <CreateEditOrderBlends
            blends={order.blends as OrderBlendUpsertDto[]}
            profile={props.user}
            isVrtLoad={false}
            orderId={props.mode.create ? "" : props.mode.initialOrder.id}
            updateBlends={updateOrderBlends}
            setUnrecoverableDataError={props.setUnrecoverableDataError}
            enabled={formEnabled}
          />
          <div className="w-100 d-flex justify-content-end mx-1">
            <div className="w-100 d-flex justify-content-end mx-1">
              <div className="flex-fill">
                <BoldButton className="btn-ghost-secondary" variant="link" onClick={navigateBack}>
                  <i className="bi-chevron-left"/> Back
                </BoldButton>
              </div>
              <SpinnerButton
                className="w-25 m-1 rounded btn-ghost-primary"
                spinning={useCreateEdit.isLoading}
              onClick={() => saveClicked()}>
              {formEnabled ? createOrUpdateCapitalized : 'Close'}
              </SpinnerButton>
              {formEnabled && <SpinnerButton
                className="w-25 m-1 rounded btn-ghost-primary"
                spinning={useCreateEdit.isLoading}
                onClick={() => saveAndAssignClicked()}>
                {createOrUpdateCapitalized + ' and Assign'}
              </SpinnerButton>}
            </div>
          </div>
        </>
      }
    </div>
  );
}
