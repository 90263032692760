import React, { useState } from "react";
import useInterval from "../Common/useInterval";
import useGetIntegrationOrderExportStatuses, { IntegrationOrderExportStatusDto, OrderStatus } from "../../requests/useGetIntegrationOrderExportStatuses";
import { Table } from "react-bootstrap";
import { WidgetDisplay } from "./WidgetDisplay";
import { WidgetProps } from "./DashboardContext";

export const IntegrationOrderExportStatus = (props: WidgetProps) => {
  const refreshTimeMs = 30000;
  const [data, setData] = useState<IntegrationOrderExportStatusDto[] | null>(null);

  const getIntegrationOrderExportStatuses = useGetIntegrationOrderExportStatuses(
    { orderCount: 7, },
    { onSuccess: (resp) => setData(SortIntegrationOrderExportStatuses(resp.integrationOrderExportStatuses)) }
  );

  useInterval(() => getIntegrationOrderExportStatuses.query(), refreshTimeMs, false);

  const integrationOrderExportStatusIndicator = (orderStatus: IntegrationOrderExportStatusDto) =>
    orderStatus.state === OrderStatus.Processed ? 'bi-circle-fill traffic-light-green'
      : orderStatus.state === OrderStatus.Warning ? 'bi-triangle-fill traffic-light-yellow'
        : 'bi-clock-fill text-primary';

  return <WidgetDisplay
    widgetId={props.id}
    title="Integration Order Export Status"
    path="/integrationOrderExportStatus"
    isLoading={data === null && getIntegrationOrderExportStatuses.isLoading}>
    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
      {getIntegrationOrderExportStatuses.isError || data?.length === 0 ?
        <h2 className="h-100 w-100 d-flex justify-content-center align-items-center pb-5" style={{ color: 'darkslategrey' }}>
          No integration orders available
        </h2>
        : <div className="row d-flex justify-content-around">
          <Table borderless style={{ tableLayout: 'fixed' }}>
            <tbody>
              {data?.slice(0, 7).map((orderStatus) =>
                <tr key={`integration-order-${orderStatus.orderNumber}`}>
                  <td className="col-1 py-0">
                    <i className={integrationOrderExportStatusIndicator(orderStatus)} />
                  </td>
                  <td className="col-3 p-0 pe-1">
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {orderStatus.orderNumber}
                    </div>
                  </td>
                  <td className="col-4 p-0 pe-1">
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {orderStatus.siteNames}
                    </div>
                  </td>
                  <td className="col-4 p-0">
                    {new Date(orderStatus.completedAt).toLocaleString('en-US',
                      { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })}
                  </td>
                </tr>)}
            </tbody>
          </Table>
        </div>
      }
    </div>
  </WidgetDisplay>
}

export const IntegrationOrderExportStatusIndicator = (orderStatus: IntegrationOrderExportStatusDto) =>
  orderStatus.state === OrderStatus.Processed ? 'bi-circle-fill traffic-light-green'
    : orderStatus.state === OrderStatus.Warning ? 'bi-triangle-fill traffic-light-yellow'
      : 'bi-clock-fill text-primary';

export const SortIntegrationOrderExportStatuses = (statuses: IntegrationOrderExportStatusDto[]) =>
  statuses.sort((a, b) => prioritizeByStatus(a, b) || prioritizeByTimeStamp(a, b));

const prioritizeByStatus = (a: IntegrationOrderExportStatusDto, b: IntegrationOrderExportStatusDto) =>
  a.state === b.state ? 0
    : a.state === OrderStatus.Warning ? -1
      : b.state === OrderStatus.Warning ? 1
        : a.state === OrderStatus.Processing ? -1
          : b.state === OrderStatus.Processing ? 1
            : 0;

const prioritizeByTimeStamp = (a: IntegrationOrderExportStatusDto, b: IntegrationOrderExportStatusDto) =>
  new Date(a.completedAt) < new Date(b.completedAt) ? -1
    : new Date(a.completedAt) > new Date(b.completedAt) ? 1
      : 0;