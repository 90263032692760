import React, { useState } from 'react';
import useGetDashboardLayout, { DashboardLayoutResponse, WidgetDto, WidgetType } from '../../requests/useGetDashboardLayout';
import useUpdateDashboardLayout from '../../requests/useUpdateDashboardLayout';
import QueryView from '../Common/QueryView';
import { AggregateProduct } from './AggregateProductWidget';
import { Dashboard, DashboardContext, Widget } from './DashboardContext';
import { IntegrationOrderExportStatus } from './IntegrationOrderExportStatusWidget';
import { SitesStatus } from './SitesStatusWidget';
import { TransportsLoaded } from './TransportsLoadedWidget';

const mapToWidget = (widget: WidgetDto): Widget => {
  const emptyRef = React.createRef<HTMLDivElement>();
  switch (widget.type) {
    case WidgetType.AggregateProductLoaded:
      return ({
        ...widget,
        component: <AggregateProduct id={widget.id} />,
        componentRef: emptyRef
      });
    case WidgetType.SiteConnectionStatus:
      return ({
        ...widget,
        component: <SitesStatus id={widget.id} />,
        componentRef: emptyRef
      });
    case WidgetType.TransportsLoaded:
      return ({
        ...widget,
        component: <TransportsLoaded id={widget.id} />,
        componentRef: emptyRef
      });
    case WidgetType.IntegrationOrderExportStatus:
      return ({
        ...widget,
        component: <IntegrationOrderExportStatus id={widget.id} />,
        componentRef: emptyRef
      });
    default:
      throw new Error(`Unknown widget type: ${widget.type}`);
  }
}

type ManagerProps = {
  dashboardLayout: DashboardLayoutResponse,
  children?: React.ReactNode
}

const DashboardProviderManger: React.FC<ManagerProps> = ({ dashboardLayout, children }) => {
  const updateDashboardLayout = useUpdateDashboardLayout();
  const [widgets, setWidgets]= useState<Widget[]>(dashboardLayout.widgets.map(w => mapToWidget(w)!));

  if (widgets.length < 3) {
    setWidgets([
      mapToWidget({ id: "widget1", type: WidgetType.AggregateProductLoaded, index: 0 }),
      mapToWidget({ id: "widget2", type: WidgetType.SiteConnectionStatus, index: 1 }),
      mapToWidget({ id: "widget3", type: WidgetType.TransportsLoaded, index: 2 }),
      mapToWidget({ id: "widget4", type: WidgetType.IntegrationOrderExportStatus, index: 3 })
    ]);
  }

  const UpdateDashboardLayout = (updatedWidgets: Widget[]) => {
    updateDashboardLayout.request(updatedWidgets.map((w) => ({ type: w.type, index: w.index })));
    setWidgets(updatedWidgets);
  }

  const dashboard: Dashboard = {
    widgets: widgets.sort(function (a, b) { return a.index - b.index; }),
    updateDashboardLayout: UpdateDashboardLayout,
  }

  return (
    <DashboardContext.Provider value={dashboard}>
      {children}
    </DashboardContext.Provider>
  );

}

type Props = {
  children?: React.ReactNode
};

export const DashboardProvider: React.FC<Props> = ({ children }) => {

  const getDashboardLayout = useGetDashboardLayout();

  return <QueryView
    query={getDashboardLayout}
    renderData={(response) =>
      <DashboardProviderManger dashboardLayout={response}>
        {children}
      </DashboardProviderManger>
    } />
  
}