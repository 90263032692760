import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import CountUp from 'react-countup';

type ProductLoadedDisplayProps = {
  siteNames: string[];
  siteAmounts: number[];
  timeframe: string;
  startingAt: string;
}

const ProductLoadedDisplay: React.FC<ProductLoadedDisplayProps> = (props: ProductLoadedDisplayProps) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const labels = props.siteNames;
  const siteColors = ['rgba(27, 110, 194)', 'rgba(41, 138, 43)'];

  const data = {
    labels,
    datasets: [
      {
        data: props.siteAmounts,
        backgroundColor: siteColors,
        categoryPercentage: 0.95,
        barPercentage: 1,
      },
    ],
  };

  function labelHeightOverflow(context: Context) {
    var meta = context.chart.getDatasetMeta(0);
    if (meta === undefined) return false;
    var height: any = meta.data[context.dataIndex].$animations.height;
    if (height === undefined) return false;
    return height._to < 35;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: { enabled: false },
      datalabels: {
        formatter: function (value: number) {
          return Intl.NumberFormat("en-US").format(value);
        },
        font: {
          size: 18,
        },
        color: function(context: Context) {
          return labelHeightOverflow(context) ? 'black' : 'white'
        },
        align: function(context: Context) {
          return labelHeightOverflow(context) ? 'end' : 'center'
        },
        padding: 10
      },
    },
    scales: {
      x: { display: false },
      y: { display: false }
    },
  };

  return <>
    <Bar options={options} data={data} />
    <p className="p-1 mb-0 d-flex justify-content-between">
      <b>{props.timeframe}</b>
      <small className="my-auto" style={{ fontSize: 12 }}>
        {props.startingAt.endsWith("M") ? "Starting at" : "Starting on"} {props.startingAt}
      </small>
    </p>
    <GraphLegend
      names={props.siteNames}
      amounts={props.siteAmounts}
      colors={siteColors}
    />
  </>
}

type graphLegendProps = {
  names: string[],
  amounts: number[],
  colors: string[]
}

function GraphLegend(props: graphLegendProps) {
  return <ul className="list-unstyled list-py-1">
    {props.names.map((name, index) => 
      <li
        key={index}
        className="display-inline"
        style={{
          color: "#333",
          listStyle: "none",
          textAlign: "left",
          display: "flex",
          flexDirection: "row",
          margin: "4px",
          whiteSpace: "nowrap",
        }}>
        <div
          className="my-auto"
          style={{
            marginRight: "8px",
            borderRadius: "50%",
            width: "15px",
            height: "15px",
            backgroundColor: props.colors[index]
          }}
        />
        <b>
          {name + " "} 
          <CountUp
            decimals={props.amounts[index] % 1 !== 0 ? 2 : 0}
            duration={1.25}
            end={props.amounts[index]} />
        </b>
      </li>)}
  </ul>;
}

export { ProductLoadedDisplay };
