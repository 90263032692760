import React, { useState } from "react"
import useInterval from "../Common/useInterval";
import useGetSitesStatus, { SiteStatusDto, Status } from "../Requests/useGetSitesStatus";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { WidgetDisplay } from "./WidgetDisplay";
import { WidgetProps } from "./DashboardContext";


export const SitesStatus = (props: WidgetProps) => {
  const refreshTimeMs = 30000;
  const [data, setData] = useState<SiteStatusDto[] | null>(null);

  const getSitesStatus = useGetSitesStatus({
    onSuccess: (resp) => setData(SortSitesStatuses(resp.sitesStatus))
  });

  useInterval(() => getSitesStatus.query(), refreshTimeMs, false);

  const anySitesNotOperational = data?.some(s => s.status !== Status.Operational);

  return <WidgetDisplay
    widgetId={props.id}
    title="Site Connection Status"
    path="/sitesStatus"
    isLoading={data === null && getSitesStatus.isLoading}>
    <div className="overflow-hidden">
      {getSitesStatus.isError || data?.length === 0 ?
        <OverlayTrigger
          placement={'left'}
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip>
              {getSitesStatus.error?.message ?? "No Site statuses are available to display"}
            </Tooltip>
          }>
          <h2 className="h-100 w-100 d-flex justify-content-center align-items-center pb-5" style={{ color: 'darkslategrey' }}>No Site Status Available</h2>
        </OverlayTrigger>
        : <div>
          {anySitesNotOperational ?
            <h3 className="d-flex justify-content-center align-items-center pb-3" style={{ color: 'darkslategrey' }}>Attention Needed</h3>
            : <h3 className="h-100 w-100 d-flex justify-content-center align-items-center pb-5" style={{ color: 'darkslategrey' }}>All Sites Operational</h3>}
          <div className="row d-flex justify-content-around">
            <div className="col" style={data != undefined && data?.length > 4 ? { "columnWidth": "175px" } : { "columnWidth": "350px" }}>
              {anySitesNotOperational && data?.slice(0, 4).map((siteStatus) =>
                <OverlayTrigger
                  key={siteStatus.siteName}
                  placement={'left'}
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      {SiteStatusToDisplay(siteStatus)}
                    </Tooltip>
                  }>
                  <p className="mb-2 float-start w-100" style={{ whiteSpace: 'nowrap', overflow: "hidden", textOverflow: 'ellipsis', color: 'darkslategrey' }}>
                    <i className={`mx-2 ${SiteStatusToIndicator(siteStatus)}`}></i>{siteStatus.siteName}
                  </p>
                </OverlayTrigger>)}
            </div>
            <div className="col" style={{ "columnWidth": "175px" }}>
              {anySitesNotOperational && data?.slice(4, 8).map((siteStatus) =>
                <OverlayTrigger
                  key={siteStatus.siteName}
                  placement={'left'}
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      {SiteStatusToDisplay(siteStatus)}
                    </Tooltip>
                  }>
                  <p className="mb-2 float-start w-100" style={{ whiteSpace: 'nowrap', overflow: "hidden", textOverflow: 'ellipsis', color: 'darkslategrey' }}>
                    <i className={`mx-2 ${SiteStatusToIndicator(siteStatus)}`}></i>{siteStatus.siteName}
                  </p>
                </OverlayTrigger>)}
            </div>
          </div>
        </div>
      }
    </div>
  </WidgetDisplay>
};

export const SiteStatusToDisplay = (siteStatus: SiteStatusDto) =>
  siteStatus.status === Status.Operational ? 'Site is operational'
    : siteStatus.status === Status.Warning ? 'No recent communication'
      : 'Check Local Connector for Issues'

export const SiteStatusToIndicator = (siteStatus: SiteStatusDto) =>
  siteStatus.status === Status.Operational ? 'bi-circle-fill traffic-light-green'
    : siteStatus.status === Status.Warning ? 'bi-triangle-fill traffic-light-yellow'
      : 'bi-octagon-fill traffic-light-red'

export const SortSitesStatuses = (sitesStatuses: SiteStatusDto[]): SiteStatusDto[] =>
  sitesStatuses.sort((a, b) => prioritizeByStatus(a, b) || alphabeticallyBySiteName(a, b));

const statusOrder = Object.values(Status);

const prioritizeByStatus = (a: SiteStatusDto, b: SiteStatusDto) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);

const alphabeticallyBySiteName = (a: SiteStatusDto, b: SiteStatusDto) => a.siteName.localeCompare(b.siteName);