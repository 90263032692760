import { UniqueIdentifier } from '@dnd-kit/core';
import React from 'react';
import { WidgetType } from '../../requests/useGetDashboardLayout';

export interface WidgetProps {
  id: UniqueIdentifier;
}

export interface Widget {
  id: UniqueIdentifier;
  type: WidgetType;
  index: number;
  component: JSX.Element;
  componentRef: React.RefObject<HTMLDivElement>;
}

export interface Dashboard {
  widgets: Widget[];
  updateDashboardLayout: (updatedWidgets: Widget[]) => void;
}

export const DashboardContext = React.createContext<Dashboard>({
  widgets: [],
  updateDashboardLayout: () => null,
});
