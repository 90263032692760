import React, { useContext, useState } from 'react';
import {
  closestCenter,
  DndContext, 
  DragEndEvent, 
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Item } from '../Common/Sortable/Item';
import { DashboardContext, Widget } from './DashboardContext';


export const Dashboard = () => {
  const dashboard = useContext(DashboardContext);
  const [activeId, setActiveId] = useState<string | null>(null);

  const [widgets, setWidgets] = useState<Widget[]>(dashboard.widgets);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event: DragStartEvent) {
    const {active} = event;
    
    setActiveId(active.id.toString());
  }
  
  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;
    
    if (active.id !== over?.id) {
      setWidgets((widgets) => {
        const oldIndex: number = widgets.map(w => w.id).indexOf(active.id);
        const newIndex = widgets.map(w => w.id).indexOf(over?.id ?? "");

        const updatedWidgets = arrayMove(widgets, oldIndex, newIndex);

        dashboard.updateDashboardLayout(updatedWidgets.map(w => ({ ...w, index: updatedWidgets.indexOf(w) })))
                
        return updatedWidgets;
      });
    }
    
    setActiveId(null);
  }

  return <DndContext 
    sensors={sensors}
    collisionDetection={closestCenter}
    onDragStart={handleDragStart}
    onDragEnd={handleDragEnd}>
    <SortableContext 
      items={widgets.map(w => w.id)}>
      <ul className="mt-n5 mb-n5 mx-n3" style={
        {
          'display': 'grid',
          'gridTemplateColumns': 'repeat(3, 1fr)',
          'gap': 17,
          'padding': 10,
        } as React.CSSProperties
      }>
        {widgets.map((widget) => widget.component)}
      </ul>
    </SortableContext>
    <DragOverlay>
      {activeId ? <Item id={activeId} isDragging>
        <div dangerouslySetInnerHTML={{ __html: widgets.find(w => w.id === activeId)?.componentRef.current?.outerHTML ?? ""}} />
      </Item> : null}
    </DragOverlay>
  </DndContext>
}