import React, { useState } from 'react';
import KaAlert from '../Common/KaAlert';
import { useUser } from '../UserAccess/useUser';
import { Row, Table, Container, Nav, NavItem, NavLink } from 'reactstrap';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import useUserQuery, { UserQuerySpec } from '../Common/useUserQuery';
import useDisplayMessage from '../Common/useDisplayMessage';
import { CreateEditProduct } from './CreateEditProduct';
import ProductDto from '../Common/dtos/ProductDto';
import ProductUpsertDto from '../Common/dtos/ProductUpsertDto';
import FilterInput, { textSatisfiesFilter } from '../FilterInput';
import { SiteAssignmentForm } from '../Common/SiteAssignment/SiteAssignmentForm';
import useUserRequest, { UserRequestSpec } from '../Common/useUserRequest';
import { HttpMethod } from '../Common/useFetch';
import Confirm from '../Common/Confirm';
import BoldButton from '../Common/BoldButton';
import { Button } from 'react-bootstrap';
import { UserProfile } from '../UserAccess/UserContext';
import useGetSitesNonAdmin, { SiteDto } from '../Requests/useGetSitesNonAdmin';
import QueryView from '../Common/QueryView';
import useGetProductsSiteAssignment, { ProductSiteAssignmentDto, ProductsSiteAssignmentDto } from '../Requests/useGetProductsSiteAssignment';
import SpinnerButton from '../Common/SpinnerButton';
import useAssignProductsToSites, { AssignmentGroupsDto } from '../Requests/useAssignProductsToSites';
import { KaSelect } from '../Common/KaSelect';

const apiPath = (businessId?: string): string => `/api/businesses/${businessId}/products`;
const getSpec = (businessId?: string): UserQuerySpec => ({
  path: apiPath(businessId),
});

const apiGetSiteAssignmentPath = (businessId?: string, productId?: string): string =>
  `${apiPath(businessId)}/${productId}/siteAssignment`;
const apiSetSiteAssignmentPath = (businessId?: string, productId?: string): string =>
  `${apiPath(businessId)}/${productId}/assignToSites`;

const removeRequestSpec = (businessId?: string, productId?: string): UserRequestSpec => ({
  path: `${apiPath(businessId)}/${productId}`,
  method: HttpMethod.DELETE,
});

const ProductPage = () => {
  const user = useUser();
  const [filterText, setFilterText] = useState('');
  const [showCreateEditProductModal, setShowCreateEditProductModal] = useState(false);
  const [showSiteAssignmentForm, setShowSiteAssignmentForm] = useState(false);
  const displayMessage = useDisplayMessage();
  const [editingProduct, setEditingProduct] = useState<ProductDto | null>(null);
  const [productToAssignToSites, setProductToAssignToSites] = useState<ProductUpsertDto | null>(null);
  const [productToRemove, setProductRemove] = useState<ProductDto | undefined>(undefined);
  const [productSitesViewActive, setProductSitesViewActive] = useState(false);

  const getProducts = useUserQuery<ProductDto[]>(getSpec(user.selectedBusiness?.id), {
    onSuccess: (data) => {
      if (productToAssignToSites && data) {
        const productToAssign = data.find(p => p.name === productToAssignToSites.name);

        productToAssign ?
          assignSitesClick(productToAssign) : displayMessage.fail(
            "Something went wrong when trying to find product with name " + productToAssignToSites.name)

        setProductToAssignToSites(null);
      }
    }
  });

  const filterTextChanged = (text: string) => setFilterText(text);

  const createEditSuccess = (c: ProductUpsertDto) => {
    displayMessage.success(`Product ${c.name} ${editingProduct ? 'updated' : 'created'}`);
    getProducts.query();
    setShowCreateEditProductModal(false);
  }

  const createClick = () => {
    displayMessage.clear();
    setEditingProduct(null);
    setShowCreateEditProductModal(true);
  }

  const editClick = (c: ProductDto) => {
    displayMessage.clear();
    setEditingProduct(c);
    setShowCreateEditProductModal(true);
  }

  const assignSitesClick = (c: ProductDto) => {
    displayMessage.clear();
    setEditingProduct(c);
    setShowSiteAssignmentForm(true);
  }

  const assignSitesSuccess = () => {
    displayMessage.success(`Sites successfully updated for ${editingProduct?.name}`);
    assignSitesClosed();
    getProducts.query();
  }

  const assignSitesClosed = () => {
    setShowSiteAssignmentForm(false);
    setEditingProduct(null);
  }

  const removeSuccess = () => {
    displayMessage.success(`Product ${productToRemove?.name} removed`);
    return getProducts.query();
  };

  const removeRequest = useUserRequest<void, void>(
    removeRequestSpec(user.selectedBusiness?.id, productToRemove?.id), {
    onSuccess: removeSuccess,
    onError: (err) => displayMessage.fail(err.message),
    onComplete: () => setProductRemove(undefined),
  });

  const changeView = (productsSiteView: boolean) => {
    setProductSitesViewActive(productsSiteView);
    getProducts.query();
  }

  if (getProducts.isLoading || removeRequest.isLoading) return <CenteredSpinner />
  if (getProducts.isError) return <h3>{getProducts.error?.message}</h3>

  return <Container>
    {showCreateEditProductModal &&
      <CreateEditProduct
        onSuccess={createEditSuccess}
        setProductToAssignToSites={setProductToAssignToSites}
        user={user}
        initialProduct={editingProduct}
        onHide={() => setShowCreateEditProductModal(false)} />
    }
    {showSiteAssignmentForm &&
      <SiteAssignmentForm
        title={`Select sites for ${editingProduct?.name}`}
        getAssignedSitesPath={apiGetSiteAssignmentPath(user.selectedBusiness?.id, editingProduct?.id)}
        assignSitePath={apiSetSiteAssignmentPath(user.selectedBusiness?.id, editingProduct?.id)}
        onHide={assignSitesClosed}
        onSuccess={assignSitesSuccess} />
    }
    <Confirm
      visible={!!productToRemove}
      title='Remove product'
      body={`When a product is removed it is no longer available to dispense. 
               The product cannot be restored. 
               Order transactions will not be removed but will no longer link to this product.
               Are you sure that you want to remove product ${productToRemove?.name}?`}
      onDismiss={() => setProductRemove(undefined)}
      onConfirm={() => removeRequest.request()} />
    <Row className="justify-content-between mb-2">
      <h2 className="col-auto ka-blue">Products</h2>
      <BoldButton className="col-auto btn-ghost-primary" onClick={createClick}>Create Product</BoldButton>
    </Row>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    <form>
      <div className="row gx-2 gx-md-3 mb-7 justify-content-between">
        <div className="col-md-4 mb-2 mb-md-0">
          <FilterInput placeholder='Filter products by keyword' onFilterTextChange={filterTextChanged} value={filterText} />
        </div>
        <div className="col-auto">
          <Nav>
            <NavItem>
              <NavLink data-testid='tableView' active={!productSitesViewActive} onClick={() => changeView(false)}>
                <i className="bi-table nav-icon" style={{ "fontSize": 24 }} />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink data-testid='swimlaneView' active={productSitesViewActive} onClick={() => changeView(true)}>
                <i className="bi-layout-three-columns nav-icon" style={{ "fontSize": 24 }} />
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    </form>
    {!productSitesViewActive ? <ProductsView 
        user={user}
        filterText={filterText}
        products={getProducts.data}
        setProductRemove={setProductRemove}
        editClick={editClick}
        assignSitesClick={assignSitesClick} /> :
      <ProductsSitesView 
        user={user} 
        filterText={filterText}
        products={getProducts.data} 
        displayMessageSuccess={displayMessage.success}
        displayMessageFail={displayMessage.fail} />}
  </Container>
}

interface ProductsViewProps {
  user: UserProfile;
  filterText: string;
  products: ProductDto[] | null;
  setProductRemove: (product: ProductDto | undefined) => void;
  editClick: (product: ProductDto) => void;
  assignSitesClick: (product: ProductDto) => void;
}

const ProductsView = (props: ProductsViewProps) => {
  const alphabeticallyByName = (a: ProductDto, b: ProductDto) => a.name.localeCompare(b.name);

  const containsFilterText = (product: ProductDto) => {
    const satisfiesFilter = textSatisfiesFilter(props.filterText);
    return satisfiesFilter(product.name) || satisfiesFilter(product.epaNumber) || satisfiesFilter(product.ticketNotes)
      || product.sites.find(s => satisfiesFilter(s.name));
  };

  return <Table bordered>
      <thead>
        <tr>
          <th>Name</th>
          <th>Sites</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.products?.filter(containsFilterText).sort(alphabeticallyByName).map(product =>
          <tr key={product.id}>
            <td>{product.name}</td>
            <td>
              <span className="badge bg-primary rounded-pill ms-1 mt-2">
                {product.sites.length}
              </span>
            </td>
            <td align="right">
              <div className="dropdown">
                <Button className='dropdown-toggle btn-ghost-secondary' variant='link' id='actionsMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                  <strong>Actions</strong>
                </Button>
                <div className='dropdown-menu' aria-labelledby='actionsMenuButton'>
                  <BoldButton className='dropdown-item btn-ghost-secondary' onClick={() => props.editClick(product)}>Edit</BoldButton>
                  <BoldButton className='dropdown-item btn-ghost-secondary' onClick={() => props.assignSitesClick(product)}>Assign sites</BoldButton>
                  <BoldButton className='dropdown-item btn-ghost-secondary' onClick={() => props.setProductRemove(product)}>Remove</BoldButton>
                </div>
              </div>
            </td>
          </tr>)}
      </tbody>
    </Table>
}

interface ProductsSitesViewProps {
  user: UserProfile;
  filterText: string;
  products: ProductDto[] | null;
  displayMessageSuccess: (message: string) => void;
  displayMessageFail: (message: string) => void;
}

const ProductsSitesView = (props: ProductsSitesViewProps) => {
  const [firstSite, setFirstSite] = useState<SiteDto | null>(null);
  const [firstSiteAssignedProducts, setFirstSiteAssignedProducts] = useState<ProductsSiteAssignmentDto>();
  const [firstSiteHasChanges, setFirstSiteHasChanges] = useState(false);
  const [firstSiteNewSelection, setFirstSiteNewSelection] = useState<SiteDto | undefined>(undefined);

  const [secondSite, setSecondSite] = useState<SiteDto | null>(null);
  const [secondSiteAssignedProducts, setSecondSiteAssignedProducts] = useState<ProductsSiteAssignmentDto>();
  const [secondSiteHasChanges, setSecondSiteHasChanges] = useState(false);
  const [secondSiteNewSelection, setSecondSiteNewSelection] = useState<SiteDto | undefined>(undefined);

  const alphabeticallyByName = (a: ProductDto, b: ProductDto) => a.name.localeCompare(b.name);

  const containsFilterText = (product: ProductDto) => {
    const satisfiesFilter = textSatisfiesFilter(props.filterText);
    return satisfiesFilter(product.name) || satisfiesFilter(product.epaNumber) || satisfiesFilter(product.ticketNotes);
  };

  const getSites = useGetSitesNonAdmin({
    onSuccess: resp => { if (resp !== undefined && resp.length > 0) 
      onFirstSiteChange(resp.sort((a,b) => a.name.localeCompare(b.name)).at(0)!); },
  });

  const getFirstSiteProductAssignments = useGetProductsSiteAssignment(firstSite?.id ?? "", {
    autoQuery: false,
    onSuccess: resp => setFirstSiteAssignedProducts(resp),
    onError: err => props.displayMessageFail(err.message)
  });

  const getSecondSiteProductAssignments = useGetProductsSiteAssignment(secondSite?.id ?? "", {
    autoQuery: false,
    onSuccess: resp => setSecondSiteAssignedProducts(resp),
    onError: err => props.displayMessageFail(err.message)
  });

  const tryChangeFirstSite = (site: SiteDto) => {
    setFirstSiteNewSelection(site);
    setSecondSiteNewSelection(undefined);

    if (!firstSiteHasChanges) onFirstSiteChange(site);
  }

  const tryChangeSecondSite = (site: SiteDto) => {
    setSecondSiteNewSelection(site);
    setFirstSiteNewSelection(undefined);

    if (!secondSiteHasChanges) onSecondSiteChange(site);
  }

  const onFirstSiteChange = (site: SiteDto) => {
    setFirstSite(site);
    setFirstSiteHasChanges(false);
    setFirstSiteNewSelection(undefined);
    if (secondSite === site) setSecondSite(null);
    getFirstSiteProductAssignments.query();
  }

  const onSecondSiteChange = (site: SiteDto) => {
    setSecondSite(site);
    setSecondSiteHasChanges(false);
    setSecondSiteNewSelection(undefined);
    if (firstSite === site) setFirstSite(null);
    getSecondSiteProductAssignments.query();
  }

  const findProductAssignment = (assignments: ProductSiteAssignmentDto[], productId: string): ProductSiteAssignmentDto | undefined =>
    assignments.find(a => a.productId === productId);

  const toggleProduct = (ev: React.ChangeEvent<HTMLInputElement>, forFirstSite: boolean, productId: string) => {
    const updatedAssignments: ProductsSiteAssignmentDto = JSON.parse(JSON.stringify(forFirstSite ? firstSiteAssignedProducts : secondSiteAssignedProducts));
    findProductAssignment(updatedAssignments.assignments, productId)!.assigned = ev.target.checked;
    forFirstSite ? setFirstSiteAssignedProducts(updatedAssignments) : setSecondSiteAssignedProducts(updatedAssignments);
    forFirstSite ? setFirstSiteHasChanges(true) : setSecondSiteHasChanges(true);
  }
  
  const updateProductAssignments = useAssignProductsToSites({
    onSuccess: _ => {
      props.displayMessageSuccess("Product assignments updated for " + firstSite?.name + (!!secondSite ? " and " + secondSite?.name : ""));
      setFirstSiteHasChanges(false);
      setSecondSiteHasChanges(false);
    },
    onError: err => props.displayMessageFail(err.message)
  });

  const saveClicked = () => {
    if (!firstSite || !firstSiteAssignedProducts) return;

    let dto: AssignmentGroupsDto = { assignmentGroups: [{ 
      siteId: firstSite!.id, 
        productIds: firstSiteAssignedProducts!.assignments.filter(a => a.assigned).map(a => a.productId) }
    ]};

    if (!!secondSite && !!secondSiteAssignedProducts) dto.assignmentGroups.push({
      siteId: secondSite!.id, 
      productIds: secondSiteAssignedProducts!.assignments.filter(a => a.assigned).map(a => a.productId)
    });

    updateProductAssignments.request(dto);
  }

  return <>
    <Confirm
      visible={(!!firstSiteNewSelection && firstSiteHasChanges) || (!!secondSiteNewSelection && secondSiteHasChanges)}
      title={`Unsaved Changes for ${!!firstSiteNewSelection ? firstSite?.name : secondSite?.name}`}
      body={`You have unsaved changes. Please save your work before selecting another location, or you will lose any updates you have made.
               Are you sure that you want to change site?`}
      onDismiss={() => !!firstSiteNewSelection ? setFirstSiteNewSelection(undefined) : setSecondSiteNewSelection(undefined)}
      onConfirm={() => !!firstSiteNewSelection ? onFirstSiteChange(firstSiteNewSelection!) : onSecondSiteChange(secondSiteNewSelection!)} />
    <QueryView
      query={getSites}
      renderData={sites => <div>
        <Table borderless>
        <thead>
          <tr>
            <th className="col-4"></th>
            <th></th>
            <th className="col-4">
              <KaSelect
                id="site1"
                placeholder="Site Selection"
                value={!!firstSite ? { value: firstSite.id, label: firstSite.name } : undefined}
                onChange={(site) => { tryChangeFirstSite(sites!.find((s) => s.id === (site as { value: string, label: string })?.value)!); }}
                options={sites.filter(s => s.id !== secondSite?.id)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(site => ({ value: site.id, label: site.name }))
                } />
            </th>
            <th></th>
            <th className="col-4">
              <KaSelect
                id="site2"
                placeholder="Site Selection"
                value={!!secondSite ? { value: secondSite.id, label: secondSite.name } : undefined}
                onChange={(site) => { tryChangeSecondSite(sites!.find((s) => s.id === (site as { value: string, label: string })?.value)!); }}
                options={sites.filter(s => s.id !== firstSite?.id)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(site => ({ value: site.id, label: site.name }))
                } />
            </th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <td></td>
              <td></td>
              <td className="text-info ps-1">Select From</td>
              <td></td>
              <td className="text-info ps-1">Select From</td>
            </tr>
          {props.products?.filter(containsFilterText).sort(alphabeticallyByName).map(product =>
            <tr key={product.id}>
              <td className="border">{product.name}</td>
              <td></td>
              <td className="border">
                {getFirstSiteProductAssignments.isLoading && <CenteredSpinner />}
                {!getFirstSiteProductAssignments.isLoading && 
                  !!firstSiteAssignedProducts?.assignments && 
                  findProductAssignment(firstSiteAssignedProducts!.assignments, product.id) &&  
                <div className="form-check d-flex justify-content-center">
                  <input
                    type="checkbox"
                    style={{"height": "2.5em", "width": "2.5em"}}
                    className="form-check-input"
                    id={`cbx${product.name}-${firstSite?.name}`}
                    onChange={(ev) => toggleProduct(ev, true, product.id)}
                    disabled={!firstSite}
                    checked={findProductAssignment(firstSiteAssignedProducts!.assignments, product.id)?.assigned} />
                </div>}
              </td>
              <td></td>
              <td className="border">
                {getSecondSiteProductAssignments.isLoading && <CenteredSpinner />}
                {!getSecondSiteProductAssignments.isLoading && 
                  !!secondSiteAssignedProducts?.assignments && 
                  findProductAssignment(secondSiteAssignedProducts!.assignments, product.id) &&  
                <div className="form-check d-flex justify-content-center">
                  <input
                    type="checkbox"
                    style={{"height": "2.5em", "width": "2.5em"}}
                    className="form-check-input"
                    id={`cbx${product.name}-${secondSite?.name}`}
                    onChange={(ev) => toggleProduct(ev, false, product.id)}
                    disabled={!secondSite}
                    checked={findProductAssignment(secondSiteAssignedProducts?.assignments, product.id)?.assigned} />
                </div>}
                {!secondSiteAssignedProducts && 
                <div className="form-check d-flex justify-content-center">
                  <input type="checkbox"
                    style={{"height": "2.5em", "width": "2.5em"}}
                    className="form-check-input"
                    disabled/>
                </div>}
              </td>
            </tr>)}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end mt-3">
        <SpinnerButton
          className="w-25 m-1 rounded btn-ghost-primary align-self-end"
          spinning={updateProductAssignments.isLoading}
          onClick={() => saveClicked()}>
          Save
        </SpinnerButton></div></div>}
    />
  </>
}

export { ProductPage };