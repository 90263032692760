import React, { useRef, useState } from "react"
import useInterval from "../Common/useInterval";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { WidgetDisplay } from "./WidgetDisplay";
import useGetTransportsLoaded, { TransportsLoadedResponse } from "../Requests/useGetTransportsLoaded";
import CountUp from "react-countup";
import { CenteredSpinner } from "../Common/CenteredSpinner";
import { useUser } from "../UserAccess/useUser";
import useUserQuery, { UserQuerySpec } from "../Common/useUserQuery";
import SiteDto from "../Common/dtos/SiteDto";
import useLocalStorage from "../Common/useLocalStorage";
import { WidgetProps } from "./DashboardContext";
import { KaSelect } from "../Common/KaSelect";

const getSitesSpec = (businessId?: string): UserQuerySpec => ({
  path: `/api/businesses/${businessId}/sites`,
});

export const TransportsLoaded = (props: WidgetProps) => {
  const user = useUser();
  const business = user.selectedBusiness;
  const getSites = useUserQuery<SiteDto[]>(getSitesSpec(business?.id), { autoQuery: business?.administrator });
  const [storedSiteId, setStoredSiteId] = useLocalStorage<string | undefined>("transportsSite", undefined);
  const refreshTimeMs = 30000;
  const [data, setData] = useState<TransportsLoadedResponse | null>(null);

  const getTransportsLoaded = useGetTransportsLoaded({
    timeZoneOffset: new Date().getTimezoneOffset(),
    siteId: storedSiteId === 'AllSites' ? undefined : storedSiteId,
  }, {
    onSuccess: (resp) => {
      setData(resp);
    }
  });

  useInterval(() => getTransportsLoaded.query(), refreshTimeMs, false);

  const TransportCounterWithTime = ({ amount, displayText, dateText, error }:
    {
      amount?: number,
      displayText: string,
      dateText?: string,
      error?: string
    }) => {
    return <div className="w-100">
      <div className="row px-0 d-flex justify-content-center">
        <h4 className="ka-blue mb-0 w-auto">
          {amount === undefined
            ? <OverlayTrigger
              placement={'right'}
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>
                  {getTransportsLoaded.error?.message ?? error ?? "Unexpected error occurred"}
                </Tooltip>
              }>
              <span className="text-muted">N/A</span>
            </OverlayTrigger>
            : <CountUp
              end={amount!}
              suffix={amount! === 1 ? " load" : " loads"}
              duration={0.5}
              redraw={false}
              preserveValue={true} />}
        </h4>
      </div>
      <div className="row px-0 d-flex justify-content-center">
        <p className="mb-0 w-auto" style={{ fontSize: 13 }}>{displayText}</p>
      </div>
      {dateText &&
        <div className="row px-0 d-flex justify-content-center">
          <p className="mb-0 w-auto" style={{ fontSize: 11 }}>{dateText}</p>
        </div>
      }
    </div>
  };

  const Settings = (modalId: string) => {

    var selectedSite = useRef<any>(null);

    if (getSites.isLoading) return <div className="modal fade" id={modalId} tabIndex={-1} role="dialog" aria-labelledby={modalId + "Label"} aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id={modalId + "Label"}>Transport Loaded Settings</h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body mt-3">
            <CenteredSpinner />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save</button>
          </div>
        </div>
      </div>
    </div>

    if (getSites.isError) return <div className="modal fade" id={modalId} tabIndex={-1} role="dialog" aria-labelledby={modalId + "Label"} aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id={modalId + "Label"}>Transport Loaded Settings</h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body mt-3">
            <h3>{getSites.error?.message}</h3>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save</button>
          </div>
        </div>
      </div>
    </div>

    const sortedSites = getSites.data?.sort((a, b) => a.name.localeCompare(b.name));

    if (sortedSites?.length === 0) return <h3>No sites found</h3>

    const sortedSiteOptions = sortedSites!
      .map(s => ({ value: s.id, label: s.name }));
    const AggregateSite = { value: 'AllSites', label: "All Sites" };
    const options = [AggregateSite].concat(sortedSiteOptions);

    const saveSettings = () => {
      console.log(selectedSite.current?.getValue());
      setStoredSiteId(selectedSite.current?.getValue()[0].value);
    }

    const currentSiteOption = options.find((option) => option.value === storedSiteId!)

    const clearSettings = () => {
      selectedSite.current!.setValue(currentSiteOption);
    }

    return <div className="modal fade" id={modalId} tabIndex={-1} role="dialog" aria-labelledby={modalId + "Label"} aria-hidden="true" data-bs-backdrop="static">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id={modalId + "Label"}>Transport Loaded Settings</h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => clearSettings()}></button>
          </div>
          <div className="modal-body mt-3">
            <h5>Show transports for:</h5>
            <KaSelect
              ref={selectedSite}
              options={options}
              defaultValue={currentSiteOption}
              />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => saveSettings()}>Save</button>
          </div>
        </div>
      </div>
    </div>
  }

  return <WidgetDisplay
    widgetId={props.id}
    title="Transports Loaded"
    isLoading={data === null && getTransportsLoaded.isLoading}
    settings={Settings}>
    <div className="row mb-2 px-3 mt-2">
      <div className="col ps-0">
        <TransportCounterWithTime
          amount={data?.loadedAmountHour}
          displayText={"Within the hour"}
          dateText={`Starting ${data?.startOfHour}`}
          error={getTransportsLoaded.error?.message}
        />
      </div>
      <div className="col pe-0">
        <TransportCounterWithTime
          amount={data?.loadedAmountDay}
          displayText={"Today"}
          dateText={`Starting ${data?.startOfDay}`}
          error={getTransportsLoaded.error?.message}
        />
      </div>
    </div>
    <div className="row mb-2 px-3">
      <div className="col ps-0">
        <TransportCounterWithTime
          amount={data?.loadedAmountWeek}
          displayText={"This week"}
          dateText={`Starting on ${data?.startOfWeek}`}
          error={getTransportsLoaded.error?.message}
        />
      </div>
      <div className="col pe-0">
        <TransportCounterWithTime
          amount={data?.loadedAmountMonth}
          displayText={"This month"}
          dateText={`Starting on ${data?.startOfMonth}`}
          error={getTransportsLoaded.error?.message}
        />
      </div>
    </div>
    <div className="row mb-2 px-3">
      <div className="col ps-0">
        <TransportCounterWithTime
          amount={data?.loadedAmountYear}
          displayText={"Year to date"}
          error={getTransportsLoaded.error?.message}
        />
      </div>
    </div>
  </WidgetDisplay>
};
