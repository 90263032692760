import { QueryOptions } from "../components/Common/useQuery";
import useUserQuery from "../components/Common/useUserQuery";
import { useSelectedBusiness } from "../components/UserAccess/useSelectedBusiness";

export enum WidgetType {
  AggregateProductLoaded = "AggregateProductLoaded",
  SiteConnectionStatus = "SiteConnectionStatus",
  TransportsLoaded = "TransportsLoaded",
  IntegrationOrderExportStatus = "IntegrationOrderExportStatus",
}

export interface WidgetDto {
  id: string;
  type: WidgetType;
  index: number;
}

export type DashboardLayoutResponse = {
  widgets: WidgetDto[]
}

const useGetDashboardLayout = (options?: QueryOptions<DashboardLayoutResponse>) => {
  const business = useSelectedBusiness();
  return useUserQuery<DashboardLayoutResponse>({ path: `/api/businesses/${business.id}/dashboardLayout` }, options);
}

export default useGetDashboardLayout;
