import React, { useState } from "react"
import CountUp from "react-countup"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useLocalStorage from "../Common/useLocalStorage";
import SelectSearch, { SelectOption } from "../Common/Forms/SelectSearch";
import useInterval from "../Common/useInterval";
import useGetProductLoaded, { ProductLoadedDto, ProductLoadedResponse } from "../Requests/useGetProductLoaded";
import { WidgetDisplay } from "./WidgetDisplay";
import { WidgetProps } from "./DashboardContext";

export const units = ["Pound", "Gallon", "Ton"]

export const AggregateProduct = (props: WidgetProps) => {
  const refreshTimeMs = 30000;

  const [previousViewedProduct, setPreviousViewedProduct] = useLocalStorage<string | null>("previousProduct", null);
  const [previousViewedUnit, setPreviousViewedUnit] = useLocalStorage<string | null>("previousUnit", null);
  const [unit, setUnit] = useState<string | null>(previousViewedUnit);
  const [currentProduct, setCurrentProduct] = useState<ProductLoadedDto>();
  const [oldProduct, setOldProduct] = useState<ProductLoadedDto>();
  const [data, setData] = useState<ProductLoadedResponse | null>(null);

  const getAggregateProduct = useGetProductLoaded({
    unit: unit ?? units[0],
    timeZoneOffset: new Date().getTimezoneOffset(),
  }, {
    onSuccess: (resp) => {
      setData(resp);
      updateCurrentProduct(resp.productsLoaded.find((p) => p.productName === previousViewedProduct)
        ?? resp.productsLoaded.sort((a, b) => a.productName.localeCompare(b.productName))[0]);
    }
  });

  const updateCurrentProduct = (product: ProductLoadedDto | null) => {
    if (product != null) {
      setOldProduct(currentProduct);
      setCurrentProduct(product);
      setPreviousViewedProduct(product.productName);
    }
  }

  const updateCurrentUnit = (unit: string) => {
    setUnit(unit);
    setPreviousViewedUnit(unit);
    updateCurrentProduct(data?.productsLoaded?.find((p) => p.productName === currentProduct?.productName)!);
  }

  useInterval(() => getAggregateProduct.query(), refreshTimeMs, false);

  const countUpSeconds = 0.5;

  const CounterWithTime = ({ amount, oldAmount, displayText, dateText, error }:
    {
      amount: number | null,
      oldAmount: number | null,
      displayText: string,
      dateText: string,
      error: string | null
    }) => {
    return <>
      <h5 className="ka-blue mb-0">
        {amount == null
          ? <OverlayTrigger
            placement={'right'}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip>
                {getAggregateProduct.error?.message ?? error ?? "Unexpected error occurred"}
              </Tooltip>
            }>
            <span className="text-muted">N/A</span>
          </OverlayTrigger>
          : <CountUp
            start={oldAmount ?? 0}
            end={amount!}
            decimals={amount % 1 !== 0 ? 2 : 0}
            duration={countUpSeconds} />}
      </h5>
      <p className="mb-0" style={{ fontSize: 13 }}>{displayText}</p>
      <p className="mb-0" style={{ fontSize: 11 }}>{dateText}</p>
    </>
  }

  return <WidgetDisplay
    widgetId={props.id}
    title="Aggregate Product Loaded"
    path="/productLoaded"
    isLoading={data === null && getAggregateProduct.isLoading}>
    <div className="row mb-3">
      <div className="col-8 d-flex justify-content-center pe-2 ps-0">
        <div className="w-100">
          <SelectSearch
            id="product"
            selectedValue={
              (data != null && data!.productsLoaded.length > 0)
                ? previousViewedProduct!
                : "none"}
            onChange={(product) => { updateCurrentProduct(data!.productsLoaded.find((p) => p.productName === product)!); }}
            options={(data != null && data!.productsLoaded.length > 0) ?
              data.productsLoaded
                .sort((a, b) => a.productName.localeCompare(b.productName))
                .map<SelectOption>(p => ({ value: p.productName, name: p.productName }))
              : [({ value: "none", name: "None" })]
            } />
        </div>
      </div>
      <div className="col d-flex justify-content-center px-0">
        <div className="w-100">
          <SelectSearch
            id="unit"
            selectedValue={unit ?? units[0]}
            hideSearch={true}
            onChange={(unit) => { updateCurrentUnit(unit) }}
            options={units.map<SelectOption>(u => ({ value: u, name: u + "s" }))} />
        </div>
      </div>
    </div>
    <div className="row mb-2 px-3">
      <div className="col ps-0">
        <CounterWithTime
          amount={currentProduct?.loadedAmountHour!}
          oldAmount={oldProduct?.loadedAmountHour ?? 0}
          displayText={"Within the hour"}
          dateText={data?.startOfHour ? "Starting " + data.startOfHour : ""}
          error={currentProduct?.error!} />
      </div>
      <div className="col pe-0">
        <CounterWithTime
          amount={currentProduct?.loadedAmountDay!}
          oldAmount={oldProduct?.loadedAmountDay ?? 0}
          displayText={"Today"}
          dateText={data?.startOfDay ? "Starting " + data.startOfDay : ""}
          error={currentProduct?.error!} />
      </div>
    </div>
    <div className="row px-3">
      <div className="col ps-0">
        <CounterWithTime
          amount={currentProduct?.loadedAmountWeek!}
          oldAmount={oldProduct?.loadedAmountWeek ?? 0}
          displayText={"This week"}
          dateText={data?.startOfWeek ? "Starting on " + data.startOfWeek : ""}
          error={currentProduct?.error!} />
      </div>
      <div className="col pe-0">
        <CounterWithTime
          amount={currentProduct?.loadedAmountMonth!}
          oldAmount={oldProduct?.loadedAmountMonth ?? 0}
          displayText={"This month"}
          dateText={data?.startOfMonth ? "Starting on " + data.startOfMonth : ""}
          error={currentProduct?.error!} />
      </div>
    </div>
  </WidgetDisplay>
};
