import React, { useState } from "react";
import useInterval from "../Common/useInterval";
import useGetIntegrationOrderExportStatuses, { IntegrationOrderExportStatusDto, OrderStatus } from "../../requests/useGetIntegrationOrderExportStatuses";
import { Table } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { SortIntegrationOrderExportStatuses } from "./IntegrationOrderExportStatusWidget";
import { CenteredSpinner } from "../Common/CenteredSpinner";
import { Container } from "reactstrap";

const integrationOrderExportStatusMessage = (orderStatus: IntegrationOrderExportStatusDto) =>
  orderStatus.state === OrderStatus.Processed ? 'Order was processed'
    : orderStatus.state === OrderStatus.Warning ? 'Order was not received'
      : 'Order is processing';

const IntegrationOrderExportStatusPage = () => {
  const refreshTimeMs = 30000;
  const [data, setData] = useState<IntegrationOrderExportStatusDto[] | null>(null);

  const getIntegrationOrderExportsStatuses = useGetIntegrationOrderExportStatuses(
    { orderCount: 100, },
    { onSuccess: (resp) => setData(SortIntegrationOrderExportStatuses(resp.integrationOrderExportStatuses)) }
  );

  useInterval(() => getIntegrationOrderExportsStatuses.query(), refreshTimeMs, false);

  if (data === null && getIntegrationOrderExportsStatuses.isLoading) return <CenteredSpinner />
  if (getIntegrationOrderExportsStatuses.isError) return <h3>{getIntegrationOrderExportsStatuses.error?.message}</h3>
  if (data?.length === 0) return <h3>No integration orders available</h3>

  return <>
    <div className="ms-n2 pb-3">
      <NavLink className="card-link my-auto text-nowrap" to="/" style={{ fontSize: 20 }}>
        <i className="bi-chevron-left" />
        Back
      </NavLink>
    </div>
    <Container>
      <h2 className="col-auto ka-blue pb-2">Integration Order Export Status</h2>
      <Table bordered>
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Sites</th>
            <th>Status Message</th>
            <th>Date/Time</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((orderStatus) =>
            <tr key={`integration-order-${orderStatus.orderNumber}`}>
              <td>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <NavLink to={`/orders/completed/${orderStatus.orderNumber}`}>{orderStatus.orderNumber}</NavLink> 
                </div>
              </td>
              <td>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {orderStatus.siteNames}
                </div>
              </td>
              <td>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {integrationOrderExportStatusMessage(orderStatus)}
                </div>
              </td>
              <td>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {new Date(orderStatus.completedAt).toLocaleString('en-US',
                  { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })}
                </div>
              </td>
            </tr>)}
        </tbody>
      </Table>
    </Container>
  </>
}

export { IntegrationOrderExportStatusPage }