import React, { useState } from 'react';
import KaAlert from '../Common/KaAlert'
import { KaEmployeeProfile } from '../UserAccess/UserContext';
import { useDisplayMessage } from '../Common/useDisplayMessage';
import OrderDto, { OrderDisplayDto } from '../Common/dtos/OrderDto'
import { CreateEditOrderHeader, OrderHeader } from '../Orders/CreateEditOrderHeader'
import OrderUpsertDto, { OrderBlendUpsertDto } from '../Common/dtos/OrderUpsertDto';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import SpinnerButton from '../Common/SpinnerButton';
import BoldButton from '../Common/BoldButton';
import { CreateEditOrderBlends } from '../Orders/CreateEditOrderBlends';

interface IProps {
  employee: KaEmployeeProfile;
  order: OrderDto;
  onClose: () => void;
  setUnrecoverableDataError: (exception: Error, severityLevel?: SeverityLevel) => void;
}

enum OrderSteps {
  Order,
  Blends
}

const initialOrder = (order: OrderDto): OrderUpsertDto => {
  const {
    id,
    hasTransactions,
    customerName,
    customerAccountNumber,
    itemNames,
    siteNames,
    createdAt,
    completedAt,
    ...upsertDto } = { ...(order as OrderDisplayDto) };
  return upsertDto;
}

export const ViewOrder = (props: IProps) => {
  const [order, setOrder] = useState<OrderUpsertDto>(initialOrder(props.order));
  const [orderStep, setOrderStep] = useState<OrderSteps>(OrderSteps.Order);
  const displayMessage = useDisplayMessage();
  const formEnabled = false;

  const updateOrderHeader = (updatedOrder: OrderHeader) => {
    if (formEnabled) {
      setOrder({ ...order, ...updatedOrder });
    }

    setOrderStep(OrderSteps.Blends);
  }

  const updateOrderBlends = (blends: OrderBlendUpsertDto[]) => {
    if (formEnabled) {
      setOrder({ ...order, blends: [...blends] });
    }
  }

  const closeClicked = () => props.onClose();

  const navigateBack = () => {
    displayMessage.clear();
    setOrderStep(OrderSteps.Order);
  }

  const title = `View order ${props.order.number}`;

  return (
    <div>
      <div className="w-100 d-flex">
        <h2 className="ka-blue">{title}</h2>
      </div>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      {orderStep === OrderSteps.Order &&
        <CreateEditOrderHeader
          orderHeader={{ ...order }}
          nextClicked={updateOrderHeader}
          profile={props.employee}
          cancelClicked={closeClicked}
          enabled={formEnabled}
        />
      }
      {orderStep === OrderSteps.Blends &&
        <>
          <CreateEditOrderBlends
            blends={order.blends as OrderBlendUpsertDto[]}
            profile={props.employee}
            isVrtLoad={false}
            orderId={props.order.id}
            updateBlends={updateOrderBlends}
            setUnrecoverableDataError={props.setUnrecoverableDataError}
            enabled={formEnabled}
          />
          <div className="w-100 d-flex justify-content-end mx-1">
            <div className="w-100 d-flex justify-content-end mx-1">
              <div className="flex-fill">
                <BoldButton className="btn-ghost-secondary" variant="link" onClick={navigateBack}>
                  <i className="bi-chevron-left"/> Back
                </BoldButton>
              </div>
              <SpinnerButton
                className="w-25 m-1 rounded btn-ghost-primary"
                spinning={false}
                onClick={closeClicked}>
                Close
              </SpinnerButton>
            </div>
          </div>
        </>
      }
    </div>
  );
}
