import { QueryOptions } from "../Common/useQuery";
import useUserQuery from "../Common/useUserQuery";
import { useUser } from "../UserAccess/useUser";

export type ProductLoadedDto = {
  productName: string,
  loadedAmountHour?: number,
  loadedAmountDay?: number,
  loadedAmountWeek?: number,
  loadedAmountMonth?: number,
  error?: string
}

export type ProductLoadedResponse = {
  startOfHour?: string,
  startOfDay?: string,
  startOfWeek?: string,
  startOfMonth?: string,
  productsLoaded: ProductLoadedDto[]
}

export type PathParams = {
  unit: string,
  timeZoneOffset: number,
  siteId?: string,
}

const useGetProductLoaded = (params: PathParams, options?: QueryOptions<ProductLoadedResponse>) => {
  const user = useUser();
  const baseUrl = `/api/businesses/${user.selectedBusiness?.id!}/productLoaded`;
  const urlParams = new URLSearchParams();

  if (params.timeZoneOffset) urlParams.append('timeZoneOffset', params.timeZoneOffset.toString());
  if (params.siteId) urlParams.append('siteId', params.siteId);
  if (params.unit) urlParams.append('unit', params.unit);

  return useUserQuery<ProductLoadedResponse>(
    { path: `${baseUrl}?${urlParams.toString()}` },
    options);
}

export default useGetProductLoaded;
