import React, { useContext } from 'react';
import { CenteredSpinner } from '../Common/CenteredSpinner';
import { NavLink } from 'react-router-dom';
import { SortableItem } from '../Common/Sortable/SortableItem';
import { UniqueIdentifier } from '@dnd-kit/core';
import { DashboardContext } from './DashboardContext';

type Props = {
  widgetId: UniqueIdentifier;
  title: string;
  path?: string;
  isLoading: Boolean;
  children: React.ReactNode;
  settings?: (modalId: string) => JSX.Element;
}

export const WidgetDisplay: React.FC<Props> = ({ widgetId, title, path, isLoading, children, settings }) => {

  const NavLinkWrapper = ({ path, children }: { path?: string, children: React.ReactNode }) => {
    return (path !== undefined ?
      <NavLink to={path} className="advanced-view text-decoration-none text-reset">
        <div className="btn m-0 position-absolute bottom-0 end-0 translate-end">
          <i className="bi bi-box-arrow-in-down-right ka-blue" style={{ fontSize: "1.5em", backgroundColor: "white" }} />
        </div>
        {children}
      </NavLink>
      : <>{children}</>)
  };

  const modalId = `${title.replace(/ /g, '')}WidgetModal`

  const dashboard = useContext(DashboardContext);
  const widget = dashboard.widgets.find((w) => w.id === widgetId);

  return <>
    {settings && settings(modalId)}
    <SortableItem key={widgetId.toString()} id={widgetId.toString()}>
      <div className="card h-100 border position-relative" style={{ minWidth: 400, minHeight: 250, maxHeight: 250 }} ref={widget?.componentRef}>
        {settings &&
          <button className="btn m-0 position-absolute top-0 end-0 translate-end" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
            <i className="bi bi-gear ka-blue" style={{ fontSize: "1.25em" }} />
          </button>}
        <div className="card-header card-header-content-sm-between mb-0 pb-0 pt-3">
          <h4 className="card-header-title text-center ka-blue">{title}</h4>
        </div>
        <hr className="hr mt-2 mb-2 mx-5" />
        <NavLinkWrapper path={path}>
          <div className="card-body py-0 px-4 mt-0 h-100">
            {isLoading ?
              <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                <CenteredSpinner />
              </div>
              : children
            }
          </div>
        </NavLinkWrapper>
      </div>
    </SortableItem>
  </>
};