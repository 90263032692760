import React from 'react';
import { Row, Table } from 'reactstrap';
import KaAlert from '../../Common/KaAlert';
import { CenteredSpinner } from '../../Common/CenteredSpinner';
import { UserQuerySpec } from '../../Common/useUserQuery';
import BoldButton from '../../Common/BoldButton';
import useDisplayMessage from '../../Common/useDisplayMessage';
import OrderTransactionDto from '../../Common/dtos/OrderTransactionDto';
import { Profile } from '../../UserAccess/UserContext';
import OrderDto from '../../Common/dtos/OrderDto';
import useUserOrEmployeeQuery from '../../Common/useUserOrEmployeeQuery';

interface IProps {
  profile: Profile,
  order: OrderDto,
  backClicked?: () => void
}

const getSpec = (businessId?: string, orderId?: string): UserQuerySpec => ({
  path: `api/ordertransactions/businesses/${businessId}/orders/${orderId}`
});

const OrderTransactionPage = (props: IProps) => {
  const getOrderTransactions = useUserOrEmployeeQuery<OrderTransactionDto[]>(getSpec(props.profile.selectedBusiness?.id, props.order.id), {

  });
  const displayMessage = useDisplayMessage();

  if (getOrderTransactions.isLoading) return <CenteredSpinner />
  if (getOrderTransactions.isError) return <h3>{getOrderTransactions.error?.message}</h3>

  const sortedOrderTransactions = getOrderTransactions.data?.sort((a, b) => new Date(b.loadedAt).getTime() - new Date(a.loadedAt).getTime());

  const displayBlendName = (orderTransaction: OrderTransactionDto, i: number) => orderTransaction.blends?.length > 1 ? `Blend ${i + 1}` : "Blend";

  return <>
    <h2 className="col-auto ka-blue">Order Transactions</h2>
    <Row className="justify-content-between mb-2">
      <BoldButton className="col-auto btn-ghost-secondary" variant="link" onClick={props.backClicked}>
        <i className="bi-chevron-left" /> Back
      </BoldButton>
    </Row>
    <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
    {sortedOrderTransactions?.length === 0 && <h4>No Order Transactions found for Order {props.order.number}</h4>}

    <Row>
      <Table borderless>
        <tbody>
          {sortedOrderTransactions?.map(orderTransaction =>
            <React.Fragment key={orderTransaction.id}>
              <tr>
                <th colSpan={2} className={"fw-bold"} style={{ borderBottom: "2px solid #677788" }}>
                  <h3>Order Transaction {orderTransaction.number}</h3>
                </th>
              </tr>
              <tr>
                <th className={"fw-bold"}>Transaction Details</th>
                <td>
                  <Table className={"w-auto"} bordered>
                    <thead>
                      <tr>
                        <th>Order Number</th>
                        <th>Number</th>
                        <th>Client Ticket</th>
                        <th>Loaded At</th>
                        <th>Site Name</th>
                        <th>Acres</th>
                        <th>Order Ref</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{orderTransaction.orderNumber}</td>
                        <td>{orderTransaction.number}</td>
                        <td>{orderTransaction.clientTicketRef}</td>
                        <td>{new Date(orderTransaction.loadedAt).toLocaleString()}</td>
                        <td>{orderTransaction.siteName}</td>
                        <td>{orderTransaction.acres}</td>
                        <td>{orderTransaction.orderRef}</td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
              <tr>
                <th className={"fw-bold"}>Customer</th>
                <td>
                  <Table className={"w-auto"} bordered>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Street</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Postal Code</th>
                        <th>Country</th>
                        <th>Customer Ref</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{orderTransaction.customer.name}</td>
                        <td>{orderTransaction.customer.street}</td>
                        <td>{orderTransaction.customer.city}</td>
                        <td>{orderTransaction.customer.state}</td>
                        <td>{orderTransaction.customer.postalCode}</td>
                        <td>{orderTransaction.customer.country}</td>
                        <td>{orderTransaction.customer.customerRef}</td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
              {orderTransaction.destination &&
                <tr>
                  <th className={"fw-bold"}>Destination</th>
                  <td>
                    <Table className={"w-auto"} bordered>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Street</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Postal Code</th>
                          <th>Country</th>
                          <th>Destination Ref</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{orderTransaction.destination.name}</td>
                          <td>{orderTransaction.destination.street}</td>
                          <td>{orderTransaction.destination.city}</td>
                          <td>{orderTransaction.destination.state}</td>
                          <td>{orderTransaction.destination.postalCode}</td>
                          <td>{orderTransaction.destination.country}</td>
                          <td>{orderTransaction.destination.destinationRef}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
              }
              {orderTransaction.driver &&
                <tr>
                  <th className={"fw-bold"}>Driver</th>
                  <td>
                    <Table className={"w-auto"} bordered>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Number</th>
                          <th>Driver Ref</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{orderTransaction.driver.name}</td>
                          <td>{orderTransaction.driver.number}</td>
                          <td>{orderTransaction.driver.driverRef}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
              }
              {orderTransaction.branch &&
                <tr>
                  <th className={"fw-bold"}>Branch</th>
                  <td>
                    <Table className={"w-auto"} bordered>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Number</th>
                          <th>Branch Ref</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{orderTransaction.branch.name}</td>
                          <td>{orderTransaction.branch.number}</td>
                          <td>{orderTransaction.branch.branchRef}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
              }
              {
                orderTransaction.transports &&
                <tr>
                  <th className={"fw-bold"}>Transport</th>
                  <td>
                    <Table className={"w-auto"} bordered>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Number</th>
                          <th>Transport Ref</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          orderTransaction.transports.map(transport =>
                            <tr key={`transport-${transport.transportRef}`}>
                              <td>{transport.name}</td>
                              <td>{transport.number}</td>
                              <td>{transport.transportRef}</td>
                            </tr>
                          )
                        }
                      </tbody>
                    </Table>
                  </td>
                </tr>
              }
              {
                orderTransaction.compartments &&
                <tr>
                  <th className={"fw-bold"}>Transport Compartments</th>
                  <td>
                    <Table className={"w-auto"} bordered>
                      <thead>
                        <tr>
                          <th>Compartment Index</th>
                          <th>Transport Ref</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          orderTransaction.compartments
                            .map(compartment =>
                              <tr key={`compartment-${compartment.transportCompartmentIndex}-${compartment.transportRef}`}>
                                <td>{compartment.transportCompartmentIndex}</td>
                                <td>{compartment.transportRef}</td>
                              </tr>
                            )
                        }
                      </tbody>
                    </Table>
                  </td>
                </tr>
              }
              {orderTransaction.carrier &&
                <tr>
                  <th className={"fw-bold"}>Carrier</th>
                  <td>
                    <Table className={"w-auto"} bordered>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Number</th>
                          <th>Carrier Ref</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{orderTransaction.carrier.name}</td>
                          <td>{orderTransaction.carrier.number}</td>
                          <td>{orderTransaction.carrier.carrierRef}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
              }
              {orderTransaction.blends?.map((blend, i) =>
                <tr key={blend.id}>
                  <th>
                    <div className={"fw-bold"}>{displayBlendName(orderTransaction, i)}</div>
                    <br />
                    <div className={"fw-bold"}>Blend Ref</div>
                    <div>{blend.blendRef}</div>
                  </th>
                  <td>
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Item Name</th>
                          <th>Item Ref</th>
                          <th>Measurements</th>
                          <th>Blend Item Ref</th>
                        </tr>
                      </thead>
                      <tbody>
                        {blend.items.map((item, i) =>
                          <tr key={`${blend.id}-${item.productRef}`}>
                            <td>{item.itemName}</td>
                            {item.productRef && <td>{item?.productRef}</td>}
                            {item.recipeRef && <td>{item?.recipeRef}</td>}
                            <td>
                              <Table bordered size="sm">
                                <thead>
                                  <tr>
                                    <th>Amount</th>
                                    <th>Unit</th>
                                    <th>Ingredient Name</th>
                                    <th>Legal For Trade</th>
                                    <th>Compartment Index</th>
                                    <th>Density Value</th>
                                    <th>Density Mass Unit</th>
                                    <th>Density Volume Unit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {item.measurements.map((measurement, i) =>
                                    <tr key={`${measurement.ingredientName}-${item.blendItemRef}`}>
                                      <td>{measurement.amount}</td>
                                      <td>{measurement.unit}</td>
                                      <td>{measurement.ingredientName}</td>
                                      <td>{measurement.legalForTrade.toString()}</td>
                                      <td>{measurement.compartmentIndex}</td>
                                      <td>{measurement.density?.value}</td>
                                      <td>{measurement.density?.massUnit}</td>
                                      <td>{measurement.density?.volumeUnit}</td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </td>
                            <td>{item.blendItemRef}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          )}
        </tbody>
      </Table>
    </Row>
  </>
}

export { OrderTransactionPage };