import React from 'react';
import { KaEmployeeTemplate } from '../UserAccess/KaEmployeeTemplate';
import { UserTemplate } from '../UserAccess/UserTemplate';
import NavPageLink from './NavPageLink'
import { useKaEmployee } from '../UserAccess/useKaEmployee';

const KaEmployeeLinks = () => {
  const employee = useKaEmployee();

  return <>
    {employee.canEditBusinesses &&
      <NavPageLink
        businessNotRequired
        defaultPath="/employee/business"
        linkDisplayText="Businesses" />
    }
    {employee.canViewNotifications &&
      <NavPageLink
        businessNotRequired
        defaultPath="/employee/siteNotifications"
        linkDisplayText="Site Notifications" />
    }
    {employee.canViewSiteStatus &&
      <NavPageLink
        businessNotRequired
        defaultPath="/employee/sitesStatus"
        linkDisplayText="Sites Status" />}
    {employee.canViewOrders &&
      <NavPageLink
        defaultPath="/employee/orders"
        linkDisplayText="Orders" />}
  </>
}

export function NavSideMenu() {
  return <nav className="navbar navbar-expand-sm navbar-sidebar navbar-vertical navbar-light bg-white border-bottom border-end">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sideNavbar" aria-controls="sideNavbar" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-default">
        <i className="bi-list"></i>
      </span>
      <span className="navbar-toggler-toggled">
        <i className="bi-x"></i>
      </span>
    </button>
    <div className="collapse navbar-collapse navbar-sidebar-aside-body" id="sideNavbar">
      <ul className="navbar-nav nav nav-vertical nav-tabs nav-tabs-borderless">
        <KaEmployeeTemplate>
          <KaEmployeeLinks />
        </KaEmployeeTemplate>
        <UserTemplate>
          <NavPageLink
            defaultPath="/"
            iconName="speedometer2"
            linkDisplayText="Dashboard" />
          <NavPageLink
            defaultPath="/branches"
            iconName="geo-alt"
            linkDisplayText="Branches" />
          <NavPageLink
            defaultPath="/customers"
            iconName="people"
            linkDisplayText="Customers" />
          <NavPageLink
            defaultPath="/drivers"
            iconName="people"
            linkDisplayText="Drivers" />
          <NavPageLink
            defaultPath="/orders"
            iconName="file-ruled"
            linkDisplayText="Orders" />
          <NavPageLink
            defaultPath="/products"
            iconName="diagram-3"
            linkDisplayText="Products" />
          <NavPageLink
            defaultPath="/recipes"
            iconName="diagram-3"
            linkDisplayText="Recipes" />
          <NavPageLink
            defaultPath="/transports"
            iconName="truck"
            linkDisplayText="Transports" />
          <NavPageLink
            defaultPath="/carriers"
            iconName="truck"
            linkDisplayText="Carriers" />
        </UserTemplate>
      </ul>
    </div>
    <div className="position-absolute bottom-0 start-0">
      <a href="https://www.kahlerautomation.com" target="_blank" rel="noreferrer" aria-label="Logo">
        <img className="m-2" src="/KA-RGB-color_logo.png" alt="Logo" width='100' style={{ minWidth: '15rem', maxWidth: '15rem' }} />
      </a>
    </div>
  </nav>
}
