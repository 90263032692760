import React from 'react';
import { Col, Row } from 'reactstrap';
import { Profile } from '../UserAccess/UserContext';
import { UserQuerySpec } from '../Common/useUserQuery';
import CustomerDto from '../Common/dtos/CustomerDto'
import { CustomerDestinationSelect } from '../Customers/CustomerDestinationSelect';
import { FormText } from '../Common/Forms/FormText';
import { Path, useForm } from 'react-hook-form';
import OrderUpsertDto from '../Common/dtos/OrderUpsertDto';
import { FormTextArea } from '../Common/Forms/FormTextArea';
import QueryView from '../Common/QueryView';
import { customerDisplayNameFromDto } from '../../Utilities';
import BoldButton from '../Common/BoldButton';
import { FormSelectSearch } from '../Common/Forms/SelectSearch';
import { BranchDto } from '../../requests/useGetBranches';
import useUserOrEmployeeQuery from '../Common/useUserOrEmployeeQuery';

interface IProps {
  profile: Profile,
  orderHeader: OrderHeader,
  nextClicked: (updatedOrder: OrderHeader) => void,
  cancelClicked: () => void,
  enabled: boolean,
}

export type OrderHeader = Pick<OrderUpsertDto,
  "number" |
  "customerId" |
  "purchaseOrder" |
  "acres" |
  "ticketNotes" |
  "operatorNotes" |
  "destinationId" |
  "branchId">

const getCustomerSpec = (businessId?: string): UserQuerySpec => ({
  path: `/api/businesses/${businessId}/customers`,
});

const getBranchesSpec = (businessId?: string): UserQuerySpec => ({
  path: `/api/businesses/${businessId}/branches`,
});

export const CreateEditOrderHeader = (props: IProps) => {
  const getCustomers = useUserOrEmployeeQuery<CustomerDto[]>(getCustomerSpec(props.profile.selectedBusiness?.id));
  const getBranches = useUserOrEmployeeQuery<BranchDto[]>(getBranchesSpec(props.profile.selectedBusiness?.id));

  const { register, handleSubmit, watch, setValue, getValues, formState: { errors } } = useForm<OrderHeader>({
    defaultValues: { ...props.orderHeader }
  });

  watch("customerId");

  const customerOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue("customerId", e.target.value, { shouldValidate: true });
    setValue("destinationId", "00000000-0000-0000-0000-000000000000");
  }

  const formProps = (name: Path<OrderHeader>) => ({
    enabled: props.enabled,
    labelSpace: 4,
    inputSpace: 7,
    name,
    setValue,
    register,
    errors
  })

  const customerOptions = (customers: CustomerDto[]) => {
    const options = customers.sort((a, b) => a.name.localeCompare(b.name))
      .map(c => ({ value: c.id, name: customerDisplayNameFromDto(c) }));

    return options;
  }

  const branchOptions = (branches: BranchDto[]) => {
    const options = branches
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(b => ({ value: b.id, name: b.name }));

    return [{ value: "00000000-0000-0000-0000-000000000000", name: "Not assigned" }, ...options];
  }

  return (<div title="">
    <Row>
      <Col>
        <FormText
          {...formProps("number")}
          noPlaceholder={!props.enabled}
          options={{
            required: 'Must enter an order number',
            maxLength: { value: 50, message: 'Must be less than 50 characters' }
          }}
        />
        <FormText
          {...formProps("purchaseOrder")}
          noPlaceholder={!props.enabled}
          options={{
            maxLength: { value: 50, message: 'Must be less than 50 characters' }
          }}
        />
        <FormText
          {...formProps("acres")}
          noPlaceholder={!props.enabled}
          options={{
            setValueAs: v => v ? Number(v) : 0,
            validate: {
              number: v => !isNaN(v) || 'Must be a number',
              greaterThanZero: v => v >= 0 || 'Must not be negative'
            }
          }}
        />
      </Col>
      <Col>
        <QueryView
          query={getCustomers}
          renderData={(customers) =>
            <FormSelectSearch
              id="customerId"
              {...formProps("customerId")}
              displayName="Customer"
              selectedValue={getValues("customerId")}
              options={{ required: "Must select a customer" }}
              selectOptions={customerOptions(customers)}
              placeholder={"Select a customer"}
              disabled={!props.enabled}
              onChange={customerOnChange}
            />
          }
        />
        <CustomerDestinationSelect
          id="destinationId"
          {...formProps("destinationId")}
          profile={props.profile}
          customerId={getValues("customerId")}
          selectedValue={getValues("destinationId")}
          displayName="Destination"
          placeholder="Not assigned"
          disabled={!props.enabled}
          firstOptionInList={{ value: "00000000-0000-0000-0000-000000000000", name: "Not assigned" }}
        />
        <QueryView
          query={getBranches}
          renderData={(branches) =>
            <FormSelectSearch
              id="branchId"
              {...formProps("branchId")}
              displayName="Branch"
              selectedValue={getValues("branchId")}
              selectOptions={branchOptions(branches)}
              disabled={!props.enabled}
              placeholder={"Not assigned"}
            />
          }
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormTextArea noPlaceholder={!props.enabled} {...formProps("ticketNotes")} />
      </Col>
      <Col>
        <FormTextArea noPlaceholder={!props.enabled} {...formProps("operatorNotes")} />
      </Col>
    </Row>
    <div className="w-100 d-flex justify-content-end mx-1">
      <div className="flex-fill">
        <BoldButton className="btn-ghost-secondary" variant="link" onClick={props.cancelClicked}>
          <i className="bi-chevron-left" /> Cancel
        </BoldButton>
      </div>
      <BoldButton className="btn-ghost-secondary" onClick={handleSubmit(props.nextClicked)}>
        Next <i className="bi-chevron-right" />
      </BoldButton>
    </div>
  </div>);
}
