import React, { useState } from "react";
import { Path, useForm } from "react-hook-form";
import RecipeDto from "../Common/dtos/RecipeDto";
import RecipeUpsertDto from "../Common/dtos/RecipeUpsertDto";
import { RowFormText } from "../Common/Forms/FormText";
import KaAlert from "../Common/KaAlert";
import KaModal from "../Common/KaModal";
import SpinnerButton from "../Common/SpinnerButton";
import useDisplayMessage from "../Common/useDisplayMessage";
import { HttpMethod } from "../Common/useFetch";
import useUserRequest, { UserRequestSpec } from "../Common/useUserRequest";
import { UserProfile } from "../UserAccess/UserContext";

interface EditRecipeProps {
    user: UserProfile;
    initialRecipe: RecipeDto | null;
    onDismiss: () => void;
    onUpsert: (recipeId: string, recipeName: string, assignSites: boolean) => void;
}

const path = (businessId?: string) => `/api/businesses/${businessId}/recipes`;

const createSpec = (businessId?: string): UserRequestSpec => ({
    path: path(businessId), method: HttpMethod.POST,
});

const updateSpec = (businessId?: string, productId?: string): UserRequestSpec => ({
    path: `${path(businessId)}/${productId}`, method: HttpMethod.PUT,
});

const newRecipe = (): RecipeDto => ({
    id: '',
    name: '',
    epaNumber: '',
    ticketNotes: ''
});

export const EditRecipe = (props: EditRecipeProps) => {
    const businessId = props.user.selectedBusiness?.id;
    const displayMessage = useDisplayMessage();
    const { id, ...defaultValues } = props.initialRecipe ? props.initialRecipe : newRecipe();
    const requestSpec = props.initialRecipe ? updateSpec(businessId, id) : createSpec(businessId);
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<RecipeUpsertDto>({
        defaultValues
    });
    const [assignSites, setAssignSites] = useState(false);

    const dismissed = () => {
        props.onDismiss();
        displayMessage.clear();
    };

    const upsert = useUserRequest<RecipeUpsertDto, string>(requestSpec, {
        onSuccess: (response) => {
            dismissed();
            const recipeId = props.initialRecipe ? props.initialRecipe.id : response;
            props.onUpsert(recipeId, getValues().name, assignSites);
        },
        onError: (e) => displayMessage.fail(e.message),
    });

    const formProps = (name: Path<RecipeUpsertDto>) => ({
        labelSpace: 4,
        inputSpace: 7,
        name: name,
        setValue,
        register,
        errors,
    });

    const saveAndAssignClicked = (recipeUpsert: RecipeUpsertDto) => {
      setAssignSites(true);
      upsert.request(recipeUpsert);
    }

    return (<KaModal
        title={`${props.initialRecipe ? 'Update' : 'Create'} a recipe`} onHide={dismissed} show={true}
        body={(<>
            <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
            <RowFormText displayName='Recipe Name' {...formProps('name')}
                options={{
                    required: 'Name is required',
                    validate: { length: v => v.length < 51 || 'Name cannot be longer than 50 characters' }
                }} />
            <RowFormText displayName='EPA Number' placeholder='EPA number' {...formProps('epaNumber')}
                options={{
                    validate: { length: v => v.length < 51 || 'EPA number cannot be longer than 50 characters' }
                }} />
            <RowFormText displayName='Ticket Notes' {...formProps('ticketNotes')} />
        </>)}
        footer={(
            <div className="btn-group">
              <SpinnerButton 
                className="w-25 m-1 rounded btn-ghost-primary"
                spinning={upsert.isLoading && !upsert.isError} 
                onClick={handleSubmit(upsert.request)}>
                  {`${props.initialRecipe ? 'Update' : 'Create'}`}
              </SpinnerButton>
              <SpinnerButton 
                className="w-25 m-1 rounded btn-ghost-primary"
                spinning={upsert.isLoading && !upsert.isError} 
                onClick={handleSubmit(saveAndAssignClicked)}>
                  {`${props.initialRecipe ? 'Update and Assign' : 'Create and Assign'}`}
              </SpinnerButton>
            </div>)
        } />);
};
